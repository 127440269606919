import Web3 from "web3";
import { presaleABI, IERC20 } from "./contractABI";

/* TEST NET CONFIGURATION */

export const httpRPCEndpoint =
  "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const rpcEndpoint = "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const web3 = new Web3(rpcEndpoint);

export const Network = "BSC Testnet";
export const ChainId = 97;
export const NetworkInstructions =
  "https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain";

// contract
export const tokenSaleAddress = "0x91a8fb1d260683d9062b901b65D2C84c58B9b7d5"; // change this
export const tokenSaleContract = new web3.eth.Contract(
  presaleABI,
  tokenSaleAddress
);

export const tokenAddress = "0x2A6b59A5c873eB728f87073d9FAD3Bc8Cb9e9357";

export const usdcAddress = "0x0830566192F70836A79AA0955089639421471818";

/* MAIN NET CONFIGURATION FTM
export const httpRPCEndpoint = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
export const rpcEndpoint = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
export const web3 = new Web3(rpcEndpoint);

export const Network = "BSC Testnet";
export const ChainId = 97;
export const NetworkInstructions = "https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain";

// contract
export const contractAddress = "0x69e3fd31d528E6Ca6Cc6E5fF767138Decc073091"; // change this
export const Contract = new web3.eth.Contract(presaleABI, contractAddress);

*/

// import Web3 from "web3";
// import { NFTABI } from "./contractABI";

// export const httpRPCEndpoint = "https://mainnet.infura.io/v3/ab6e88cefec9432e840dbf6d9093d4a9";

// export const rpcEndpoint = "wss://mainnet.infura.io/ws/v3/ab6e88cefec9432e840dbf6d9093d4a9";
// export const web3 = new Web3(rpcEndpoint);

// export const Network = "ETH Mainnet";
// export const ChainId = 1;
// export const NetworkInstructions = "https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain";

// /// mainnet
// export const ethWeb3 = new Web3("wss://mainnet.infura.io/ws/v3/ab6e88cefec9432e840dbf6d9093d4a9");

// // nfts
// export const nftContractAddress = "0x69e3fd31d528E6Ca6Cc6E5fF767138Decc073091"; // change thsi
// export const nftContract = new web3.eth.Contract(NFTABI, nftContractAddress);
