import {
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useState } from "react";
import "./App.css";
import TopMenu from "./navigation/TopMenu";
import Footer from "./pages/sections/Footer";
import Roadmap from "./pages/sections/Roadmap";
import Solutions from "./pages/sections/Solutions";
import FAQ from "./pages/sections/FAQ";
import Discord from "./icons/Discord";
import Telegram from "./icons/Telegram";
import Twitter from "./icons/Twitter";
import BullsInc from "./icons/BullsInc";
import PresaleProgress from "./pages/sections/PresaleProgress";
import Partners from "./pages/sections/Partners";
import Team from "./pages/sections/Team";

const issues = [
  {
    issue: "inflation",
    text: "Due to the inflationary nature of yield farms, they typically inherently lose value over time",
  },
  {
    issue: "burn",
    text: "Emission rates cannot be countered with classic burning mechanisms and buybacks",
  },
  {
    issue: "incentive",
    text: "Lack of incentives to re-stake or burn",
  },
  {
    issue: "vision",
    text: "Desirable APRs are difficult to maintain",
  },
];

const square = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 182 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ paddingTop: "4px" }}
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="3.73%" stopColor="rgba(210, 136, 62, 1)" />
        <stop offset="98.85%" stopColor="rgba(255, 205, 75, 0.73)" />
      </linearGradient>
    </defs>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="url(#gradient)"
      d="M182 91L91 0L0 91L91 182L182 91ZM138.895 91L91 43.1053L43.1053 91L91 138.895L138.895 91Z"
    />
  </svg>
);
function App() {
  const [ended, setEnded] = useState(false);

  function moveToSection(section) {
    document
      .getElementById(section)
      .scrollIntoView({ behavior: "smooth", inline: "nearest" });
  }

  return (
    <>
      <TopMenu moveToSection={moveToSection} />
      <Box
        style={{
          background: "url(./bg.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="about"
      >
        <Container>
          <Grid
            container
            alignItems="center"
            sx={{
              flexDirection: { xs: "column-reverse", sm: "row" },
              p: { xs: 5, sm: 0 },
            }}
          >
            <Grid item xs={12} md={4}>
              <BullsInc />
              <Stack className="social-stack" display="flex" direction="row">
                <a
                  href="https://discord.gg/PKMVbyEAzV"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Discord fill="url(#gradient)" />
                </a>
                <a
                  href="https://twitter.com/BullsIncNFTs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter fill="url(#gradient)" />
                </a>
              </Stack>

              {/* <Typography variant="h6">
                the <span className="bullish">ownerless</span> mutual fund{" "}
                <br />
                designed for every type of investor
              </Typography> */}
            </Grid>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={5} textAlign="center">
              <img src="./bull.png" alt="Bulls Inc" width="100%" />
              <svg
                // onClick={() => moveToSection("on-arrow-click")}
                width={12}
                height="34"
                viewBox="0 0 12 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="3.73%" stopColor="rgba(210, 136, 62, 1)" />
                    <stop
                      offset="98.85%"
                      stopColor="rgba(255, 205, 75, 0.73)"
                    />
                  </linearGradient>
                </defs>
                <path
                  className="draw"
                  d="M0.166626 27.8333L5.99996 33.6667L11.8333 27.8333L10.655 26.655L6.83329 30.4767V0.333344H5.16663V30.4767L1.34496 26.655L0.166626 27.8333Z"
                  fill="url(#gradient)"
                />
              </svg>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container id="on-arrow-click" sx={{ my: 5, py: 5 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={4}>
            <Typography variant="h6">
              <span className="bullish">Bulls Inc </span> NFTs are set up to be
              more than just your beautiful profile picture, but to provide
              holders with exclusive bullish access to the coming NFT Trading
              Utility Suite and expert community.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="text.secondary">
              Bulls Inc aims to be a blue chip token and household name in the
              NFT industry. Focusing initially on utility partnerships and
              building utility, the project roadmap is guided by a long term
              strategy for incremental success.
            </Typography>

            <a href="https://nft.bulls.inc">
              <Button className="outlined-btn" sx={{ mt: 3 }}>
                <div>Join the NFT Club</div>
                <div>
                  <svg
                    width="8"
                    height="20"
                    viewBox="0 0 12 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      transform: "rotate(-90deg)",
                      marginLeft: "16px",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M0.166626 27.8333L5.99996 33.6667L11.8333 27.8333L10.655 26.655L6.83329 30.4767V0.333344H5.16663V30.4767L1.34496 26.655L0.166626 27.8333Z"
                      fill="#D2883E"
                    />
                  </svg>
                </div>
              </Button>
            </a>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: 5, pt: 5 }}>
        {
          <div
            style={{
              overflow: "hidden",
              paddingBottom: "56.25%",
              position: "relative",
              height: "0",
            }}
          >
            <iframe
              style={{
                left: "0",
                top: "0",
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-Z57n4JU550"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        }
      </Container>

      <Container sx={{ mt: 5, pt: 5 }}>
        {
          <div
            style={{
              overflow: "hidden",
              paddingBottom: "56.25%",
              position: "relative",
              height: "0",
            }}
          >
            <iframe
              style={{
                left: "0",
                top: "0",
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/_i3xkBf5ow0?controls=0&amp;autoplay=1&amp;loop=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        }
      </Container>

      <Container>
        {/* <Grid
          container
          sx={{ my: 5, py: 5 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className="bullish" sx={{ mb: 3 }}>
              {square} Key issues we aim to solve
            </Typography>
            <Typography variant="h6">
              The objective behind Bulls Inc. is to solve the multitude of
              problems plaguing the vast majority of yield farming instruments
              currently operating in the DeFi space.
            </Typography>
            <Typography color="text.secondary" sx={{ mt: 3 }}>
              Armed with innovative solutions, the mutual fund prioritises
              robust security as well as lasting sustainability, to provide the
              safest capital yields possible.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: { xs: 5, md: 0 } }}>
            <Grid container spacing="5">
              {issues.map((issue, i) => (
                <Grid key={i} item xs={12} sm={6} sx={{ p: 3, mt: 5 }}>
                  <Paper
                    sx={{
                      border: "1px solid #2B2B2B",
                      borderRadius: "16px",
                      p: 3,
                      background: "#131313",
                      position: "relative",
                    }}
                  >
                    <div className="issue-img">
                      <img src={`${issue.issue}.svg`} alt={issue.issue} />
                    </div>
                    <Typography sx={{ mt: 5 }} color="text.secondary">
                      {issue.text}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid> */}
      </Container>
      {/* <PresaleProgress /> */}
      {/* 
      <Solutions moveToSection={moveToSection} />
      <FAQ />
      <Team /> 

       <Partners /> 
       */}
      <Roadmap />

      <Footer />
    </>
  );
}

export default App;
