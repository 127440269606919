import { Container, Typography, Paper, Grid, Box, Button } from "@mui/material";
import { isCompositeComponent } from "react-dom/cjs/react-dom-test-utils.production.min";
import Discord from "../../icons/Discord";

// const roadmap = [
//   {
//     title: "Q4 2021",
//     subtitles: {
//       title: "ANALYSIS & PREPARATION PHASE",
//       items: [
//         "DeFi market and product fit analysis",
//         "Architecture and design of Bull Fund & Dividend Pool",
//         "Private sale structure",
//         "Finalize whitepaper, litepaper and pitch deck",
//         "Onboarding partners, launchpads and advisors",
//         "Community growth campaign",
//         "Development of NFT suite",
//       ],
//     },
//   },
//   {
//     title: "Q1 2022",
//     subtitles: [
//       {
//         title: "INITIAL LAUNCH PHASE 1A",
//         items: [
//           "$pBULLS Seed round",
//           "Website reveal",
//           "Listing on insertLink[https://raritysniper.com,Rarity Sniper]",
//           "Public sale structure",
//           "NFT whitelist events",
//           "NFT minting on ETH",
//           "NFT Reveals",
//         ],
//       },
//       {
//         title: "PRESALE & LISTING PHASE 1B",
//         items: [
//           "$pBULLS Private sale rounds 1,2 & 3",
//           "Marketing materials for private investors",
//           "Commercial agreements with partners",
//           "$BULLS listing on spookyswap DEX (FTM)",
//         ],
//       },
//     ],
//   },
//   {
//     title: "Q1/Q2 2022",
//     subtitles: [
//       {
//         title: "LAUNCH ON FTM PHASE 2",
//         items: [
//           "Paladin Audit & RugDoc Audit",
//           "Investment of Bull Fund to stable yield farms to feed Dividend Pool",
//           "Launch of 1st Dividend Pool on FTM",
//           "Launch of Farms & Vaults on FTM",
//           "NFT perk reveals",
//         ],
//       },
//     ],
//   },
//   {
//     title: "Q3 2022",
//     subtitles: {
//       title: "EXPANSION PHASE 3",
//       items: [
//         "Expansion of the Bull Fund into the AVAX blockchain",
//         "Expansion of NFT perks to AVAX",
//       ],
//     },
//   },
// ];

const roadmap = [
  {
    title: "LATE Q1 2022",
    subtitles: {
      title: "PARTNERSHIPS & REPOSITIONING",
      items: [
        "Acquiring high level partnerships",
        "Discord, Twitter and website relaunch",
        "Bulls Inc. NFT Club migration towards a utility-based project",
      ],
    },
  },
  {
    title: "Q2 2022",
    subtitles: [
      {
        title: "OG MINTER PERKS, REVEAL, POST-SELLOUT & UTILITY",
        items: [
          "OG Minter Perks to be launched",
          "Re-launch utility based mint",
          "Reveal after public mint-out (special perks to our 5 mystics)",
          "Giveaways, prizes and premier access to our utility suite",
          "Bulls Inc Utility Suite (You don't want to miss this)",
        ],
      },
    ],
  },
  {
    title: "FUTURE PLANS",
    subtitles: [
      {
        title: "THE FUTURE OF BULLS INC",
        items: [
          "Continuous improvements to utility suite",
          "Access to high-level networking and connections",
          "Future collection drop",
          "Bulls Inc NFT DAO",
          "Merch and more!",
        ],
      },
    ],
  },
];

const square = (
  <svg
    width="38"
    height="38"
    viewBox="0 0 182 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="3.73%" stopColor="rgba(210, 136, 62, 1)" />
        <stop offset="98.85%" stopColor="rgba(255, 205, 75, 0.73)" />
      </linearGradient>
    </defs>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="url(#gradient)"
      d="M182 91L91 0L0 91L91 182L182 91ZM138.895 91L91 43.1053L43.1053 91L91 138.895L138.895 91Z"
    />
  </svg>
);

export default function Roadmap() {
  return (
    <>
      <Container id="roadmap">
        {/* <hr style={{ borderColor: "#D2883E" }} /> */}
        <Typography variant="h5" align="center" sx={{ mt: 5, pt: 5 }}>
          Roadmap
        </Typography>

        <Typography color="text.secondary" align="center" sx={{ mb: 5, pb: 5 }}>
          A New Direction
        </Typography>

        <Grid container>
          <Grid item xs={12} md={7} sx={{ position: "relative" }}>
            <span className="roadmap-line"></span>
            <span className="roadmap-line-done"></span>
            {roadmap &&
              roadmap.map((r, i) => (
                <Paper key={i} sx={{ p: 5, mb: 5 }}>
                  <Grid container>
                    <Grid item xs={2}>
                      <div className="roadmap-square-bg">{square}</div>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" className="bullish">
                        {r.title}
                      </Typography>

                      {r.subtitles.length > 0 ? (
                        r.subtitles.map((sub, s) => (
                          <>
                            <Typography
                              key={s}
                              variant="body2"
                              color="text.secondary"
                            >
                              {sub.title}
                            </Typography>
                            <Typography variant="body1">
                              <ul>
                                {sub.items.map((item, x) =>
                                  item.includes("insertLink") ? (
                                    <li key={x}>
                                      {item
                                        .replace("insertLink", "")
                                        .replace(
                                          item.substring(
                                            item.indexOf("[") + 1,
                                            item.lastIndexOf("]")
                                          ),
                                          ""
                                        )
                                        .replace("[", "")
                                        .replace("]", "")}

                                      <a
                                        target="_blank"
                                        className="bullish"
                                        href={item.substring(
                                          item.indexOf("[") + 1,
                                          item.lastIndexOf(",")
                                        )}
                                      >
                                        {item.substring(
                                          item.indexOf(",") + 1,
                                          item.lastIndexOf("]")
                                        )}
                                      </a>
                                    </li>
                                  ) : (
                                    <li key={x}>{item}</li>
                                  )
                                )}
                              </ul>
                            </Typography>
                          </>
                        ))
                      ) : (
                        <>
                          <Typography variant="body2" color="text.secondary">
                            {r.subtitles.title}
                          </Typography>
                          <Typography variant="body1">
                            <ul>
                              {r.subtitles.items.map((item, k) =>
                                item.includes("insertLink") ? (
                                  <li key={k}>
                                    {item
                                      .replace("insertLink", "")
                                      .replace(
                                        item.substring(
                                          item.indexOf("[") + 1,
                                          item.lastIndexOf("]")
                                        ),
                                        ""
                                      )
                                      .replace("[", "")
                                      .replace("]", "")}
                                    <a
                                      target="_blank"
                                      className="bullish"
                                      href={item.substring(
                                        item.indexOf("[") + 1,
                                        item.lastIndexOf(",")
                                      )}
                                    >
                                      {item.substring(
                                        item.indexOf(",") + 1,
                                        item.lastIndexOf("]")
                                      )}
                                    </a>
                                  </li>
                                ) : (
                                  <li key={k}>{item}</li>
                                )
                              )}
                            </ul>
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              ))}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            position="relative"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img
              src="./bull.png"
              id="bull-img"
              alt="Bull"
              style={{ width: "100%", position: "sticky", top: "96px" }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
