import { Container, Box, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { tokenSaleContract } from "../../contracts/ContractProvider";
import { useInterval } from "../../hooks/useInterval";
import CountUp, { useCountUp } from "react-countup";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#000000",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background:
      "linear-gradient(214.84deg, rgba(255, 205, 75, 0.73) 3.73%, rgba(205, 164, 111, 0) 98.85%), #D2883E;",
  },
}));

export default function PresaleProgress() {
  const [state, setState] = useState({
    progress: 0,
    amountSold: 0,
  });

  const CONTRACT_POLLING_INTERVAL = 30000; // millis
  const MAX = 3600000; // HARD CAP
  const getSaleProgress = async () => {
    try {
      const sold = await tokenSaleContract.methods.usdcRaised().call();
      console.log(sold);
      const amountSold = sold * 10 ** -6;
      setState({
        progress: (amountSold / MAX) * 100,
        amountSold: amountSold,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useInterval(() => {
    getSaleProgress();
  }, CONTRACT_POLLING_INTERVAL);

  useEffect(() => {
    getSaleProgress();
  }, []);

  return (
    <Container>
      <Box sx={{ py: 3, position: "relative" }}>
        <BorderLinearProgress variant="determinate" value={state.progress} />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={{ mt: 1 }} className="bullish">
              <CountUp
                end={state.amountSold}
                duration="3"
                suffix=" USDC"
                separator=","
                preserveValue
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ mt: 1 }}>3,600,000 USDC</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
