import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  Button,
  Stack,
} from "@mui/material";
import Discord from "../../icons/Discord";
import Telegram from "../../icons/Telegram";
import Twitter from "../../icons/Twitter";

export default function Footer() {
  return (
    <>
      <Box
        style={{
          background: "url(./bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Grid
            container
            alignItems="center"
            sx={{ height: "100%", my: 5, py: 5 }}
          >
            <Grid item xs={12} md={5}>
              <Typography variant="h5" sx={{ my: 3 }}>
                Join the Community
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ my: 3 }}>
                Get the latest news, participate in giveaways and get in contact
                with other Bulls through our Discord server!
              </Typography>

              <a
                href="https://discord.gg/PKMVbyEAzV"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="contained-btn">
                  <div>
                    <Discord fill="#131313" />{" "}
                  </div>
                  <div style={{ paddingLeft: "8px" }}>Join Our Discord</div>
                </Button>
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{ p: 5 }}
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Container>
          <Grid
            container
            display="flex"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Stack
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <img src="./bull.png" alt="Bulls Inc" height="96" />
                <img src="./bullsinc_grey.png" alt="Bulls Inc" />
              </Stack>
              <Stack>
                <Typography
                  variant="body2"
                  style={{ color: "#393939", paddingTop: "4px" }}
                  textAlign="right"
                >
                  2022 Bulls Inc. All Rights Reserved.
                </Typography>
              </Stack>
            </Stack>
            <Stack
              className="social-stack"
              display="flex"
              direction="row"
              sx={{ m: { xs: "auto", sm: "unset" }, pt: { xs: 3, sm: 0 } }}
            >
              <a href="https://discord.gg/PKMVbyEAzV" target="_blank">
                <Discord fill="url(#gradient)" />
              </a>
              <a href="https://twitter.com/thebullsinc" target="_blank">
                <Twitter fill="url(#gradient)" />
              </a>
            </Stack>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
