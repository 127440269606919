export default function BullsInc({fill}) {
    return (
<svg
                width="424"
                height="137"
                viewBox="0 0 424 137"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "100%" }}
              >
                <path
                  d="M17.5942 50.0493H57.7949C59.7896 49.9988 61.4263 49.3927 62.6538 48.1806C63.9324 46.918 64.5462 45.4534 64.5462 43.7363C64.5462 42.0697 63.9324 40.6051 62.6538 39.393C61.4263 38.1809 59.8408 37.5748 57.9484 37.5748H17.5942V50.0493ZM17.5942 25.7569H53.5498C55.4422 25.7569 57.0277 25.1509 58.3064 23.8883C59.5339 22.6257 60.1476 21.1611 60.1476 19.4945C60.1476 17.8278 59.5339 16.4137 58.3064 15.2522C57.13 13.9896 55.5957 13.3835 53.7033 13.3835H17.5942V25.7569ZM73.4456 29.0397C73.0876 29.1912 72.6784 29.5952 72.1669 30.2518C74.1105 31.0093 75.7983 32.1204 77.2815 33.686C80.8106 37.1203 82.5496 41.2111 82.5496 45.9585C82.5496 50.7563 80.8106 54.8976 77.2815 58.4834C73.7525 61.9682 69.2005 63.6853 63.5744 63.7358H0V0H60.1476C65.5691 0 69.9677 1.66663 73.3945 5.05038C76.8212 8.38363 78.509 12.3734 78.509 17.0198C78.509 21.7166 76.8212 25.7064 73.4456 29.0397Z"
                  fill="#D2883E"
                />
                <path
                  d="M17.5942 50.0493H57.7949C59.7896 49.9988 61.4263 49.3927 62.6538 48.1806C63.9324 46.918 64.5462 45.4534 64.5462 43.7363C64.5462 42.0697 63.9324 40.6051 62.6538 39.393C61.4263 38.1809 59.8408 37.5748 57.9484 37.5748H17.5942V50.0493ZM17.5942 25.7569H53.5498C55.4422 25.7569 57.0277 25.1509 58.3064 23.8883C59.5339 22.6257 60.1476 21.1611 60.1476 19.4945C60.1476 17.8278 59.5339 16.4137 58.3064 15.2522C57.13 13.9896 55.5957 13.3835 53.7033 13.3835H17.5942V25.7569ZM73.4456 29.0397C73.0876 29.1912 72.6784 29.5952 72.1669 30.2518C74.1105 31.0093 75.7983 32.1204 77.2815 33.686C80.8106 37.1203 82.5496 41.2111 82.5496 45.9585C82.5496 50.7563 80.8106 54.8976 77.2815 58.4834C73.7525 61.9682 69.2005 63.6853 63.5744 63.7358H0V0H60.1476C65.5691 0 69.9677 1.66663 73.3945 5.05038C76.8212 8.38363 78.509 12.3734 78.509 17.0198C78.509 21.7166 76.8212 25.7064 73.4456 29.0397Z"
                  fill="url(#paint0_linear_152_352)"
                />
                <path
                  d="M172.976 0V42.5242C172.976 48.1301 170.827 53.029 166.48 57.3218C162.133 61.6147 156.353 63.7358 149.039 63.7358H118.761C111.549 63.7358 105.77 61.6147 101.32 57.3218C96.9727 53.029 94.8246 48.1301 94.8246 42.5242V0H112.572V39.9485C112.572 42.7767 113.595 45.0494 115.59 46.716C117.687 48.4837 120.244 49.3422 123.313 49.3422H144.59C147.761 49.3422 150.318 48.4837 152.313 46.716C154.307 45.0494 155.33 42.7767 155.33 39.9485V0H172.976Z"
                  fill="#D2883E"
                />
                <path
                  d="M172.976 0V42.5242C172.976 48.1301 170.827 53.029 166.48 57.3218C162.133 61.6147 156.353 63.7358 149.039 63.7358H118.761C111.549 63.7358 105.77 61.6147 101.32 57.3218C96.9727 53.029 94.8246 48.1301 94.8246 42.5242V0H112.572V39.9485C112.572 42.7767 113.595 45.0494 115.59 46.716C117.687 48.4837 120.244 49.3422 123.313 49.3422H144.59C147.761 49.3422 150.318 48.4837 152.313 46.716C154.307 45.0494 155.33 42.7767 155.33 39.9485V0H172.976Z"
                  fill="url(#paint1_linear_152_352)"
                />
                <path
                  d="M257.366 49.3422L252.405 63.7358H187.297V0H204.942V49.3422H257.366Z"
                  fill="#D2883E"
                />
                <path
                  d="M257.366 49.3422L252.405 63.7358H187.297V0H204.942V49.3422H257.366Z"
                  fill="url(#paint2_linear_152_352)"
                />
                <path
                  d="M337.154 49.3422L332.193 63.7358H267.084V0H284.73V49.3422H337.154Z"
                  fill="#D2883E"
                />
                <path
                  d="M337.154 49.3422L332.193 63.7358H267.084V0H284.73V49.3422H337.154Z"
                  fill="url(#paint3_linear_152_352)"
                />
                <path
                  d="M364.773 38.7869C358.584 38.7869 353.521 36.8678 349.634 32.979C345.747 29.0902 343.803 24.4438 343.803 19.0904C343.803 13.7875 345.747 9.2927 349.634 5.50492C353.367 1.81814 358.38 0 364.773 0H419.448L414.589 13.737H366.665C365.284 13.737 364.108 14.2421 363.136 15.2016C362.216 16.1107 361.704 17.2723 361.653 18.7369C361.704 19.949 362.216 21.0601 363.136 22.0702C364.21 23.0297 365.489 23.5348 366.921 23.5348H402.416C408.912 23.5348 414.078 25.4539 417.965 29.3427C421.954 33.3325 423.949 37.9789 423.949 43.3323C423.949 48.8877 421.954 53.6856 417.965 57.6754C413.924 61.7157 408.759 63.7358 402.416 63.7358H344.621L349.48 49.9483H401.752C403.388 49.9483 404.769 49.3927 405.894 48.2816C407.02 47.1706 407.582 45.8575 407.582 44.3423C407.582 42.8272 407.02 41.5141 405.945 40.4536C404.871 39.3425 403.593 38.7869 402.007 38.7869H364.773Z"
                  fill="#D2883E"
                />
                <path
                  d="M364.773 38.7869C358.584 38.7869 353.521 36.8678 349.634 32.979C345.747 29.0902 343.803 24.4438 343.803 19.0904C343.803 13.7875 345.747 9.2927 349.634 5.50492C353.367 1.81814 358.38 0 364.773 0H419.448L414.589 13.737H366.665C365.284 13.737 364.108 14.2421 363.136 15.2016C362.216 16.1107 361.704 17.2723 361.653 18.7369C361.704 19.949 362.216 21.0601 363.136 22.0702C364.21 23.0297 365.489 23.5348 366.921 23.5348H402.416C408.912 23.5348 414.078 25.4539 417.965 29.3427C421.954 33.3325 423.949 37.9789 423.949 43.3323C423.949 48.8877 421.954 53.6856 417.965 57.6754C413.924 61.7157 408.759 63.7358 402.416 63.7358H344.621L349.48 49.9483H401.752C403.388 49.9483 404.769 49.3927 405.894 48.2816C407.02 47.1706 407.582 45.8575 407.582 44.3423C407.582 42.8272 407.02 41.5141 405.945 40.4536C404.871 39.3425 403.593 38.7869 402.007 38.7869H364.773Z"
                  fill="url(#paint4_linear_152_352)"
                />
                <path
                  d="M236.499 73.2642V137H218.905V73.2642H236.499Z"
                  fill="#D2883E"
                />
                <path
                  d="M236.499 73.2642V137H218.905V73.2642H236.499Z"
                  fill="url(#paint5_linear_152_352)"
                />
                <path
                  d="M333.421 137H315.622L269.59 95.4354V137H251.843V73.2642H269.59L315.622 114.98V73.2642H333.421V137Z"
                  fill="#D2883E"
                />
                <path
                  d="M333.421 137H315.622L269.59 95.4354V137H251.843V73.2642H269.59L315.622 114.98V73.2642H333.421V137Z"
                  fill="url(#paint6_linear_152_352)"
                />
                <path
                  d="M419.243 137H370.706C363.494 137 357.715 134.879 353.265 130.586C348.918 126.293 346.77 121.394 346.77 115.788V94.4758C346.77 88.7184 348.918 83.769 353.214 79.5772C357.561 75.3854 363.392 73.2642 370.706 73.2642H424L419.243 87.2538H375.207C372.138 87.2538 369.53 88.1628 367.433 89.9305C365.438 91.6981 364.466 94.0213 364.466 96.9V113.061C364.466 115.94 365.489 118.263 367.484 119.98C369.53 121.697 372.087 122.556 375.207 122.606H424L419.243 137Z"
                  fill="#D2883E"
                />
                <path
                  d="M419.243 137H370.706C363.494 137 357.715 134.879 353.265 130.586C348.918 126.293 346.77 121.394 346.77 115.788V94.4758C346.77 88.7184 348.918 83.769 353.214 79.5772C357.561 75.3854 363.392 73.2642 370.706 73.2642H424L419.243 87.2538H375.207C372.138 87.2538 369.53 88.1628 367.433 89.9305C365.438 91.6981 364.466 94.0213 364.466 96.9V113.061C364.466 115.94 365.489 118.263 367.484 119.98C369.53 121.697 372.087 122.556 375.207 122.606H424L419.243 137Z"
                  fill="url(#paint7_linear_152_352)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint5_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint6_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint7_linear_152_352"
                    x1="146.438"
                    y1="-53.7535"
                    x2="99.1254"
                    y2="156.626"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FFCD4B" stopOpacity="0.73" />
                    <stop offset="1" stopColor="#CDA46F" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
    )

}