import * as React from "react";

import {
  Typography,
  MenuItem,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Stack,
} from "@mui/material";

import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import TemporaryDrawer from "./Drawer";

export default function MenuAppBar({ moveToSection }) {
  const [open, setOpen] = React.useState(false);
  function moveToSectionFromDrawer(section) {
    document
      .getElementById(section)
      .scrollIntoView({ behavior: "smooth", inline: "nearest" });
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <TemporaryDrawer />
      <AppBar position="fixed">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* <IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="menu"
            sx={{ mr: 2, display: { md: "none", xs: "block" } }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton> */}

          <IconButton sx={{ mr: 2 }} onClick={() => moveToSection("about")}>
            <img src="./simple.svg" alt="Bulls Inc" height="36" />
          </IconButton>
          <Box sx={{ display: { sm: "none", xs: "block" } }}>
            <a href="https://nft.bulls.inc">
              <Button
                className="contained-btn menu-btn"
                sx={{ padding: "8px" }}
              >
                NFT Club
              </Button>
            </a>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {/* <MenuItem>
              <Typography
                color="text.secondary"
                onClick={() => moveToSection("about")}
              >
                About
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography
                color="text.secondary"
                onClick={() => moveToSection("solutions")}
              >
                Our Solutions
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography
                color="text.secondary"
                onClick={() => moveToSection("faq")}
              >
                FAQ
              </Typography>
            </MenuItem>
             <MenuItem>
              <Typography
                color="text.secondary"
                onClick={() => moveToSection("team")}
              >
                Team
              </Typography>
            </MenuItem> 
             <MenuItem>
              <Typography
                color="text.secondary"
                onClick={() => moveToSection("partners")}
              >
                Partners
              </Typography>
            </MenuItem> 

            <MenuItem>
              <Typography
                color="text.secondary"
                onClick={() => moveToSection("roadmap")}
              >
                Roadmap
              </Typography>
            </MenuItem> */}

            <MenuItem>
              <a href="https://nft.bulls.inc">
                <Button
                  className="contained-btn menu-btn"
                  sx={{ padding: "8px" }}
                >
                  NFT Club
                </Button>
              </a>
            </MenuItem>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={() => setOpen(!open)}>
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            height: "100%",
          }}
          justifyContent="center"
          onKeyDown={() => setOpen(false)}
        >
          <Stack
            display="flex"
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ py: 3 }}
          >
            <img src="./bull.png" alt="Bulls Inc" height="72" />
            <img src="./bullsinc_grey.png" alt="Bulls Inc" height="36" />
          </Stack>
          <List style={{ textIndent: "0px" }}>
            {/* <ListItem onClick={() => moveToSectionFromDrawer("about")}>
              <ListItemText primary="About" />
            </ListItem>

            <ListItem onClick={() => moveToSectionFromDrawer("solutions")}>
              <ListItemText primary="Our Solutions" />
            </ListItem>
            <ListItem onClick={() => moveToSectionFromDrawer("faq")}>
              <ListItemText primary="FAQ" />
            </ListItem>
            <ListItem onClick={() => moveToSectionFromDrawer("team")}>
              <ListItemText primary="Team" />
            </ListItem>
            <ListItem onClick={() => moveToSectionFromDrawer("partners")}>
              <ListItemText primary="Partners" />
            </ListItem> 

            <ListItem onClick={() => moveToSectionFromDrawer("roadmap")}>
              <ListItemText primary="Roadmap" />
            </ListItem> */}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
