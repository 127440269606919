import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#131313",
    },
    secondary: {
      main: "#D2883E",
    },
    text: {
      primary: "#eeecea",
      secondary: "#9c9c9c",
      orange: "#D2883E",
    },
    background: {
      paper: "#191919",
      default: "#131313",
    },
  },
  typography: {
    fontFamily: "Karla",
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#131313",
        color: "#D2883E",
      },
    },
  },
  props: {
    MuiAppBar: {
      color: "inherit",
    },
  },
});

export default theme;
